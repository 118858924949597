import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <h1 className="title">It works!</h1>
      <p className="subtitle">S3 + CloudFront + Cloudflare</p>
    </div>
  );
}

export default App;